const config = {
    API_V2_0: "https://api.dev.ladishare.com/1.0", 
    
   
    // UID
    API_UID_V2_0: 'http://api.dev.ladiuid.com/2.0',
    ACCOUNT_UID: 'http://accounts.dev.ladiuid.com',

    API_CDN: 'https://files.dev.ladicdn.com',
    DOMAIN_CDN: 'https://w.ladicdn.com',

    APP_DOMAIN: 'https://app.dev.ladishare.com',
    AFFILIATE_DOMAIN: 'https://aff.dev.ladishare.com',

    APP_AFFILIATE: false,
};

const authEndpoint = {
    AUTH_SIGNIN: `${config.ACCOUNT_UID}/signin?callback_url=https://app.dev.ladishare.com&app_code=lf`,
    AUTH_SIGNUP: `${config.ACCOUNT_UID}/signup?callback_url=https://app.dev.ladishare.com&app_code=lf`,
    AUTH_SIGNOUT: `${config.ACCOUNT_UID}/signin?logout=1&callback_url=https://app.dev.ladishare.com&app_code=lf`,
};

export default config;

export {
    authEndpoint,
};
